/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

/**
 * Ajv is a peer dependency for development builds. It's used to apply run-time validation
 * to message payloads before passing them on to the underlying analytics instance.
 *
 * Note that the production bundle does not depend on Ajv.
 *
 * You can install it with: `npm install --save-dev ajv`.
 */
import Ajv from 'ajv'
import * as Segment from './segment'

export interface AvailabilityParamenterProgressDetailsCardViewed {
	/**
	 * The day that the user selected to see more details
	 */
	day: number | null
	/**
	 * The Codes of the DDCs the user is taking this action.
	 */
	ddc_id: any[] | null
	/**
	 * The informations sent by the user
	 */
	payload: Record<string, any> | null
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
	/**
	 * The percentage of visits that was achieved for the day
	 */
	visit_percentage: number | null
}
export interface AvailabilityParamenterProgressDetailsExpanded {
	/**
	 * The number of active strategies.
	 */
	active_strategies: number | null
	/**
	 * The Codes of the DDCs the user is taking this action.
	 */
	ddc_id: any[] | null
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface AvailabilityParametersPageViewed {
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface CancelSavingAvailabilityParamenter {
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface ClientDownloadFileDownloaded {
	/**
	 * ID of the requested file
	 */
	file_id: string | null
	/**
	 * The informations retuned to the user
	 */
	response: Record<string, any> | null
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface ClientDownloadFileRequested {
	/**
	 * The informations retuned to the user
	 */
	response: Record<string, any> | null
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface ClientDownloadListViewed {
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface ClientFileUploaded {
	/**
	 * The file size that have been uploaded on the platform. Sample: 1MB, 15KBS
	 */
	file_size: number | null
	/**
	 * The informations retuned to the user
	 */
	response: Record<string, any> | null
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface ClientPaginationChanged {
	/**
	 * The new page the user has navigated to
	 */
	page: number | null
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface ClientScreenPageAccessed {
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface ClientSearchPerformed {
	/**
	 * The type of query the user is tring to do
	 */
	query_type: string | null
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The text the user typed in the searchbar
	 */
	search_text: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface ClientUploadFileDownloaded {
	/**
	 * ID of the requested file
	 */
	file_id: string | null
	/**
	 * The informations retuned to the user
	 */
	response: Record<string, any> | null
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface ClientUploadListViewed {
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface CountrySelected {
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface DdcPaginationChanged {
	/**
	 * The new page the user has navigated to
	 */
	page: number | null
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface DdcScreenPageAccessed {
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface DdcSearchPerformed {
	/**
	 * The type of query the user is tring to do
	 */
	query_type: string | null
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The text the user typed in the searchbar
	 */
	search_text: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface DeleteAvailabilityParameter {
	/**
	 * The informations sent by the user
	 */
	payload: Record<string, any> | null
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface PageViewed {
	/**
	 * The current name of the app where the event was triggered.
	 */
	app_display_name: string | null
	/**
	 * The unique id of the app where the event was triggered.
	 */
	app_id: string | null
	/**
	 * A boolean that identifies rather an occurrence of this event configures as a core action.
	 */
	is_core_event: boolean | null
	/**
	 * (IF APPLICABLE) The country of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_country: string | null
	/**
	 * (IF APPLICABLE) The name of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_display_name: string | null
	/**
	 * (IF APPLICABLE) The id of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_id: string | null
	/**
	 * (IF APPLICABLE) The Service model of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_service_model: string | null
	/**
	 * (IF APPLICABLE)  The tier of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_tier_name: string | null
	/**
	 * The Page title on the front end with localization.
	 */
	page_label: string | null
	/**
	 * The name of the page.
	 */
	page_name: string | null
	/**
	 * The URL of the page where the event was triggered.
	 */
	page_url: string | null
	/**
	 * URL the user came from to get to the current page. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/login
	 */
	referrer: string | null
	/**
	 * Logged user's country
	 */
	user_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The user permission group that relates to the application and the feature where the action is being executed.
	 */
	user_permission_group: string | null
	/**
	 * (IF APPLICABLE) The country of the vendor that is executing the action or having it executed for them.
	 */
	vendor_country: string | null
	/**
	 * (IF APPLICABLE) The name of the vendor that is executing the action or having it executed for them.
	 */
	vendor_display_name: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * (IF APPLICABLE) The Service model of the vendor that is executing the action or having it executed for them.
	 */
	vendor_service_model: string | null
	/**
	 * (IF APPLICABLE)  The tier of the vendor that is executing the action or having it executed for them.
	 */
	vendor_tier_name: string | null
	/**
	 * (IF APPLICABLE) The view level the action is being executed
	 */
	view_level: string | null
}
export interface ParametersDetailsViewed {
	/**
	 * The Codes of the DDCs the user is taking this action.
	 */
	ddc_id: any[] | null
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface ParametersListFiltered {
	/**
	 * The query parameter used for filtering on the screen
	 */
	filter_data: Record<string, any> | null
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface RegeneratedClientDeliveryWindows {
	/**
	 * The customerAccountId of the selected clients
	 */
	customer_account_id: any[] | null
	/**
	 * The Codes of the DDCs the user is taking this action.
	 */
	ddc_id: any[] | null
	/**
	 * The informations retuned to the user
	 */
	response: Record<string, any> | null
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface RegeneratedDdcDeliveryWindows {
	/**
	 * The Codes of the DDCs the user is taking this action.
	 */
	ddc_id: any[] | null
	/**
	 * The informations retuned to the user
	 */
	response: Record<string, any> | null
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface RuleDownloadFileDownloaded {
	/**
	 * The informations retuned to the user
	 */
	response: Record<string, any> | null
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface RuleDownloadFileRequested {
	/**
	 * The informations retuned to the user
	 */
	response: Record<string, any> | null
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface RuleDownloadListViewed {
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface RuleFileUploaded {
	/**
	 * The file size that have been uploaded on the platform. Sample: 1MB, 15KBS
	 */
	file_size: number | null
	/**
	 * The informations retuned to the user
	 */
	response: Record<string, any> | null
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface RuleUploadFileDownloaded {
	/**
	 * ID of the requested file
	 */
	file_id: string | null
	/**
	 * The informations retuned to the user
	 */
	response: Record<string, any> | null
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface RuleUploadListViewed {
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface SavedClient {
	/**
	 * The number of records changed in batch by this event
	 */
	batch_quantity: number | null
	/**
	 * Identify if there were any changes in the click and collect parameters.
	 */
	click_and_collect_changes: boolean | null
	/**
	 * The customerAccountId of the selected clients
	 */
	customer_account_id: any[] | null
	/**
	 * The Codes of the DDCs the user is taking this action.
	 */
	ddc_id: any[] | null
	/**
	 * Identify if there were any changes in the flex delivery dates
	 */
	flex_dates_changes: boolean | null
	/**
	 * Identify if the client or DDC has click and collect enabled
	 */
	has_click_and_collect: boolean | null
	/**
	 * Identify if the client or exception rule has flexible delivery days.
	 */
	has_flex_dates: boolean | null
	/**
	 * The informations sent by the user
	 */
	payload: Record<string, any> | null
	/**
	 * The informations retuned to the user
	 */
	response: Record<string, any> | null
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface SavedClientRules {
	/**
	 * The number of records changed in batch by this event
	 */
	batch_quantity: number | null
	/**
	 * The customerAccountId of the selected clients
	 */
	customer_account_id: any[] | null
	/**
	 * The Codes of the DDCs the user is taking this action.
	 */
	ddc_id: any[] | null
	/**
	 * Identify if the client or exception rule has flexible delivery days.
	 */
	has_flex_dates: boolean | null
	/**
	 * The informations sent by the user
	 */
	payload: Record<string, any> | null
	/**
	 * The informations retuned to the user
	 */
	response: Record<string, any> | null
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface SavedDdc {
	/**
	 * The number of records changed in batch by this event
	 */
	batch_quantity: number | null
	/**
	 * Identify if there were any changes in the click and collect parameters.
	 */
	click_and_collect_changes: boolean | null
	/**
	 * The Codes of the DDCs the user is taking this action.
	 */
	ddc_id: any[] | null
	/**
	 * Identify if the client or DDC has click and collect enabled
	 */
	has_click_and_collect: boolean | null
	/**
	 * The informations sent by the user
	 */
	payload: Record<string, any> | null
	/**
	 * The informations retuned to the user
	 */
	response: Record<string, any> | null
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface SavedDdcHolidays {
	/**
	 * The number of records changed in batch by this event
	 */
	batch_quantity: number | null
	/**
	 * The Codes of the DDCs the user is taking this action.
	 */
	ddc_id: any[] | null
	/**
	 * The informations sent by the user
	 */
	payload: Record<string, any> | null
	/**
	 * The informations retuned to the user
	 */
	response: Record<string, any> | null
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface SavedDdcRules {
	/**
	 * The number of records changed in batch by this event
	 */
	batch_quantity: number | null
	/**
	 * The Codes of the DDCs the user is taking this action.
	 */
	ddc_id: any[] | null
	/**
	 * Identify if the client or exception rule has flexible delivery days.
	 */
	has_flex_dates: boolean | null
	/**
	 * The informations sent by the user
	 */
	payload: Record<string, any> | null
	/**
	 * The informations retuned to the user
	 */
	response: Record<string, any> | null
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface SavingAvailabilityParamenter {
	/**
	 * The informations sent by the user
	 */
	payload: Record<string, any> | null
	/**
	 * The informations retuned to the user
	 */
	response: Record<string, any> | null
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface StartCreatingAvailabilityParamenter {
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface StartEditAvailabilityParamenter {
	/**
	 * The Codes of the DDCs the user is taking this action.
	 */
	ddc_id: any[] | null
	/**
	 * The informations retuned to the user
	 */
	response: Record<string, any> | null
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface StartedEditClient {
	/**
	 * The number of records changed in batch by this event
	 */
	batch_quantity: number | null
	/**
	 * The customerAccountId of the selected clients
	 */
	customer_account_id: any[] | null
	/**
	 * The Codes of the DDCs the user is taking this action.
	 */
	ddc_id: any[] | null
	/**
	 * Identify if the client or DDC has click and collect enabled
	 */
	has_click_and_collect: boolean | null
	/**
	 * Identify if the client or exception rule has flexible delivery days.
	 */
	has_flex_dates: boolean | null
	/**
	 * Boolean showing if the user entered the screen with multiple clients or just one
	 */
	multiple: boolean | null
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface StartedEditDdc {
	/**
	 * The number of records changed in batch by this event
	 */
	batch_quantity: number | null
	/**
	 * The Codes of the DDCs the user is taking this action.
	 */
	ddc_id: any[] | null
	/**
	 * Identify if the client or DDC has click and collect enabled
	 */
	has_click_and_collect: boolean | null
	/**
	 * Boolean showing if the user entered the screen with multiple clients or just one
	 */
	multiple: boolean | null
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface StartedManageClientRules {
	/**
	 * The number of records changed in batch by this event
	 */
	batch_quantity: number | null
	/**
	 * The customerAccountId of the selected clients
	 */
	customer_account_id: any[] | null
	/**
	 * The Codes of the DDCs the user is taking this action.
	 */
	ddc_id: any[] | null
	/**
	 * The query parameter used for filtering on the screen
	 */
	filter_data: Record<string, any> | null
	/**
	 * Identify if the client or exception rule has flexible delivery days.
	 */
	has_flex_dates: boolean | null
	/**
	 * Boolean showing if the user entered the screen with multiple clients or just one
	 */
	multiple: boolean | null
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface StartedManageDdcHolidays {
	/**
	 * The number of records changed in batch by this event
	 */
	batch_quantity: number | null
	/**
	 * The Codes of the DDCs the user is taking this action.
	 */
	ddc_id: any[] | null
	/**
	 * Boolean showing if the user entered the screen with multiple clients or just one
	 */
	multiple: boolean | null
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface StartedManageDdcRules {
	/**
	 * The number of records changed in batch by this event
	 */
	batch_quantity: number | null
	/**
	 * The Codes of the DDCs the user is taking this action.
	 */
	ddc_id: any[] | null
	/**
	 * The query parameter used for filtering on the screen
	 */
	filter_data: Record<string, any> | null
	/**
	 * Identify if the client or exception rule has flexible delivery days.
	 */
	has_flex_dates: boolean | null
	/**
	 * Boolean showing if the user entered the screen with multiple clients or just one
	 */
	multiple: boolean | null
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface StrategyProgressFiltered {
	/**
	 * The query parameter used for filtering on the screen
	 */
	filter_data: Record<string, any> | null
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}
export interface ViewDeliveryWindows {
	/**
	 * The customerAccountId of the selected clients
	 */
	customer_account_id: any[] | null
	/**
	 * The Codes of the DDCs the user is taking this action.
	 */
	ddc_id: any[] | null
	/**
	 * The name of the screen where the user is taking this action.
	 */
	screen_name: string | null
	/**
	 * The country that is currently selected when this event occured
	 */
	selected_country: string | null
	/**
	 * Logged user email
	 */
	user_email: string | null
	/**
	 * The Vendor ID of the item
	 */
	vendor_id: string | null
	/**
	 * The vendor name that is currently selected when this event occored
	 */
	vendor_name: string | null
}

export type ViolationHandler = (
	message: Record<string, any>,
	violations: Ajv.ErrorObject[]
) => void

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
	message,
	violations
) => {
	const msg = JSON.stringify(
		{
			type: 'Typewriter JSON Schema Validation Error',
			description:
				`You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
				'Tracking Plan spec.',
			errors: violations,
		},
		undefined,
		2
	)

	console.warn(msg)
}

let onViolation = defaultValidationErrorHandler

let analytics: () => SegmentAnalytics.AnalyticsJS | undefined = () => {
	return window.analytics
}

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
	/**
	 * Underlying analytics instance where analytics calls are forwarded on to.
	 * Defaults to window.analytics.
	 */
	analytics?: SegmentAnalytics.AnalyticsJS
	/**
	 * Handler fired when if an event does not match its spec. This handler
	 * does not fire in production mode, because it requires inlining the full
	 * JSON Schema spec for each event in your Tracking Plan.
	 *
	 * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
	 * if a message does not match the spec. Otherwise, errors will be logged to stderr.
	 */
	onViolation?: ViolationHandler
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
	analytics = options.analytics
		? () => options.analytics || window.analytics
		: analytics
	onViolation = options.onViolation || onViolation
}

/**
 * Validates a message against a JSON Schema using Ajv. If the message
 * is invalid, the `onViolation` handler will be called.
 */
function validateAgainstSchema(message: Record<string, any>, schema: object) {
	const ajv = new Ajv({ schemaId: 'auto', allErrors: true, verbose: true })
	ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-06.json'))
	ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-04.json'))

	if (!ajv.validate(schema, message) && ajv.errors) {
		onViolation(message, ajv.errors)
	}
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Segment.Options = {}): Segment.Options {
	return {
		...message,
		context: {
			...(message.context || {}),
			typewriter: {
				language: 'typescript',
				version: '7.0.2-0',
			},
		},
	}
}

/**
 * @typedef AvailabilityParamenterProgressDetailsCardViewed
 * @property {number | null} day - The day that the user selected to see more details
 * @property {any[] | null} ddc_id - The Codes of the DDCs the user is taking this action.
 * @property {Record<string, any> | null} payload - The informations sent by the user
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 * @property {number | null} visit_percentage - The percentage of visits that was achieved for the day
 */
/**
 * @typedef AvailabilityParamenterProgressDetailsExpanded
 * @property {number | null} active_strategies - The number of active strategies.
 * @property {any[] | null} ddc_id - The Codes of the DDCs the user is taking this action.
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef AvailabilityParametersPageViewed
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef CancelSavingAvailabilityParamenter
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef ClientDownloadFileDownloaded
 * @property {string | null} file_id - ID of the requested file
 * @property {Record<string, any> | null} response - The informations retuned to the user
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef ClientDownloadFileRequested
 * @property {Record<string, any> | null} response - The informations retuned to the user
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef ClientDownloadListViewed
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef ClientFileUploaded
 * @property {number | null} file_size - The file size that have been uploaded on the platform. Sample: 1MB, 15KBS
 * @property {Record<string, any> | null} response - The informations retuned to the user
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef ClientPaginationChanged
 * @property {number | null} page - The new page the user has navigated to
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef ClientScreenPageAccessed
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef ClientSearchPerformed
 * @property {string | null} query_type - The type of query the user is tring to do
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} search_text - The text the user typed in the searchbar
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef ClientUploadFileDownloaded
 * @property {string | null} file_id - ID of the requested file
 * @property {Record<string, any> | null} response - The informations retuned to the user
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef ClientUploadListViewed
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef CountrySelected
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef DdcPaginationChanged
 * @property {number | null} page - The new page the user has navigated to
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef DdcScreenPageAccessed
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef DdcSearchPerformed
 * @property {string | null} query_type - The type of query the user is tring to do
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} search_text - The text the user typed in the searchbar
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef DeleteAvailabilityParameter
 * @property {Record<string, any> | null} payload - The informations sent by the user
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef PageViewed
 * @property {string | null} app_display_name - The current name of the app where the event was triggered.
 * @property {string | null} app_id - The unique id of the app where the event was triggered.
 * @property {boolean | null} is_core_event - A boolean that identifies rather an occurrence of this event configures as a core action.
 * @property {string | null} manufacturer_country - (IF APPLICABLE) The country of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_display_name - (IF APPLICABLE) The name of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_id - (IF APPLICABLE) The id of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_service_model - (IF APPLICABLE) The Service model of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_tier_name - (IF APPLICABLE)  The tier of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} page_label - The Page title on the front end with localization.
 * @property {string | null} page_name - The name of the page.
 * @property {string | null} page_url - The URL of the page where the event was triggered.
 * @property {string | null} referrer - URL the user came from to get to the current page. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/login
 * @property {string | null} user_country - Logged user's country
 * @property {string | null} user_email - Logged user email
 * @property {string | null} user_permission_group - The user permission group that relates to the application and the feature where the action is being executed.
 * @property {string | null} vendor_country - (IF APPLICABLE) The country of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_display_name - (IF APPLICABLE) The name of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_service_model - (IF APPLICABLE) The Service model of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_tier_name - (IF APPLICABLE)  The tier of the vendor that is executing the action or having it executed for them.
 * @property {string | null} view_level - (IF APPLICABLE) The view level the action is being executed
 */
/**
 * @typedef ParametersDetailsViewed
 * @property {any[] | null} ddc_id - The Codes of the DDCs the user is taking this action.
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef ParametersListFiltered
 * @property {Record<string, any> | null} filter_data - The query parameter used for filtering on the screen
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef RegeneratedClientDeliveryWindows
 * @property {any[] | null} customer_account_id - The customerAccountId of the selected clients
 * @property {any[] | null} ddc_id - The Codes of the DDCs the user is taking this action.
 * @property {Record<string, any> | null} response - The informations retuned to the user
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef RegeneratedDdcDeliveryWindows
 * @property {any[] | null} ddc_id - The Codes of the DDCs the user is taking this action.
 * @property {Record<string, any> | null} response - The informations retuned to the user
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef RuleDownloadFileDownloaded
 * @property {Record<string, any> | null} response - The informations retuned to the user
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef RuleDownloadFileRequested
 * @property {Record<string, any> | null} response - The informations retuned to the user
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef RuleDownloadListViewed
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef RuleFileUploaded
 * @property {number | null} file_size - The file size that have been uploaded on the platform. Sample: 1MB, 15KBS
 * @property {Record<string, any> | null} response - The informations retuned to the user
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef RuleUploadFileDownloaded
 * @property {string | null} file_id - ID of the requested file
 * @property {Record<string, any> | null} response - The informations retuned to the user
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef RuleUploadListViewed
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef SavedClient
 * @property {number | null} batch_quantity - The number of records changed in batch by this event
 * @property {boolean | null} click_and_collect_changes - Identify if there were any changes in the click and collect parameters.
 * @property {any[] | null} customer_account_id - The customerAccountId of the selected clients
 * @property {any[] | null} ddc_id - The Codes of the DDCs the user is taking this action.
 * @property {boolean | null} flex_dates_changes - Identify if there were any changes in the flex delivery dates
 * @property {boolean | null} has_click_and_collect - Identify if the client or DDC has click and collect enabled
 * @property {boolean | null} has_flex_dates - Identify if the client or exception rule has flexible delivery days.
 * @property {Record<string, any> | null} payload - The informations sent by the user
 * @property {Record<string, any> | null} response - The informations retuned to the user
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef SavedClientRules
 * @property {number | null} batch_quantity - The number of records changed in batch by this event
 * @property {any[] | null} customer_account_id - The customerAccountId of the selected clients
 * @property {any[] | null} ddc_id - The Codes of the DDCs the user is taking this action.
 * @property {boolean | null} has_flex_dates - Identify if the client or exception rule has flexible delivery days.
 * @property {Record<string, any> | null} payload - The informations sent by the user
 * @property {Record<string, any> | null} response - The informations retuned to the user
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef SavedDdc
 * @property {number | null} batch_quantity - The number of records changed in batch by this event
 * @property {boolean | null} click_and_collect_changes - Identify if there were any changes in the click and collect parameters.
 * @property {any[] | null} ddc_id - The Codes of the DDCs the user is taking this action.
 * @property {boolean | null} has_click_and_collect - Identify if the client or DDC has click and collect enabled
 * @property {Record<string, any> | null} payload - The informations sent by the user
 * @property {Record<string, any> | null} response - The informations retuned to the user
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef SavedDdcHolidays
 * @property {number | null} batch_quantity - The number of records changed in batch by this event
 * @property {any[] | null} ddc_id - The Codes of the DDCs the user is taking this action.
 * @property {Record<string, any> | null} payload - The informations sent by the user
 * @property {Record<string, any> | null} response - The informations retuned to the user
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef SavedDdcRules
 * @property {number | null} batch_quantity - The number of records changed in batch by this event
 * @property {any[] | null} ddc_id - The Codes of the DDCs the user is taking this action.
 * @property {boolean | null} has_flex_dates - Identify if the client or exception rule has flexible delivery days.
 * @property {Record<string, any> | null} payload - The informations sent by the user
 * @property {Record<string, any> | null} response - The informations retuned to the user
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef SavingAvailabilityParamenter
 * @property {Record<string, any> | null} payload - The informations sent by the user
 * @property {Record<string, any> | null} response - The informations retuned to the user
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef StartCreatingAvailabilityParamenter
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef StartEditAvailabilityParamenter
 * @property {any[] | null} ddc_id - The Codes of the DDCs the user is taking this action.
 * @property {Record<string, any> | null} response - The informations retuned to the user
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef StartedEditClient
 * @property {number | null} batch_quantity - The number of records changed in batch by this event
 * @property {any[] | null} customer_account_id - The customerAccountId of the selected clients
 * @property {any[] | null} ddc_id - The Codes of the DDCs the user is taking this action.
 * @property {boolean | null} has_click_and_collect - Identify if the client or DDC has click and collect enabled
 * @property {boolean | null} has_flex_dates - Identify if the client or exception rule has flexible delivery days.
 * @property {boolean | null} multiple - Boolean showing if the user entered the screen with multiple clients or just one
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef StartedEditDdc
 * @property {number | null} batch_quantity - The number of records changed in batch by this event
 * @property {any[] | null} ddc_id - The Codes of the DDCs the user is taking this action.
 * @property {boolean | null} has_click_and_collect - Identify if the client or DDC has click and collect enabled
 * @property {boolean | null} multiple - Boolean showing if the user entered the screen with multiple clients or just one
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef StartedManageClientRules
 * @property {number | null} batch_quantity - The number of records changed in batch by this event
 * @property {any[] | null} customer_account_id - The customerAccountId of the selected clients
 * @property {any[] | null} ddc_id - The Codes of the DDCs the user is taking this action.
 * @property {Record<string, any> | null} filter_data - The query parameter used for filtering on the screen
 * @property {boolean | null} has_flex_dates - Identify if the client or exception rule has flexible delivery days.
 * @property {boolean | null} multiple - Boolean showing if the user entered the screen with multiple clients or just one
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef StartedManageDdcHolidays
 * @property {number | null} batch_quantity - The number of records changed in batch by this event
 * @property {any[] | null} ddc_id - The Codes of the DDCs the user is taking this action.
 * @property {boolean | null} multiple - Boolean showing if the user entered the screen with multiple clients or just one
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef StartedManageDdcRules
 * @property {number | null} batch_quantity - The number of records changed in batch by this event
 * @property {any[] | null} ddc_id - The Codes of the DDCs the user is taking this action.
 * @property {Record<string, any> | null} filter_data - The query parameter used for filtering on the screen
 * @property {boolean | null} has_flex_dates - Identify if the client or exception rule has flexible delivery days.
 * @property {boolean | null} multiple - Boolean showing if the user entered the screen with multiple clients or just one
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef StrategyProgressFiltered
 * @property {Record<string, any> | null} filter_data - The query parameter used for filtering on the screen
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */
/**
 * @typedef ViewDeliveryWindows
 * @property {any[] | null} customer_account_id - The customerAccountId of the selected clients
 * @property {any[] | null} ddc_id - The Codes of the DDCs the user is taking this action.
 * @property {string | null} screen_name - The name of the screen where the user is taking this action.
 * @property {string | null} selected_country - The country that is currently selected when this event occured
 * @property {string | null} user_email - Logged user email
 * @property {string | null} vendor_id - The Vendor ID of the item
 * @property {string | null} vendor_name - The vendor name that is currently selected when this event occored
 */

/**
 * Triggered when the user clicks on load more for na specific day.
 *
 * @param {AvailabilityParamenterProgressDetailsCardViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function availabilityParamenterProgressDetailsCardViewed(
	props: AvailabilityParamenterProgressDetailsCardViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on load more for na specific day.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					day: {
						description: 'The day that the user selected to see more details',
						type: ['number', 'null'],
					},
					ddc_id: {
						description:
							'The Codes of the DDCs the user is taking this action.',
						type: ['array', 'null'],
					},
					payload: {
						description: 'The informations sent by the user',
						type: ['object', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
					visit_percentage: {
						description:
							'The percentage of visits that was achieved for the day',
						type: ['number', 'null'],
					},
				},
				required: [
					'day',
					'ddc_id',
					'payload',
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
					'visit_percentage',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Availability Paramenter Progress Details Card Viewed',
		type: 'object',
	}
	const message = {
		event: 'Availability Paramenter Progress Details Card Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Availability Paramenter Progress Details Card Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user expands the line of delivey capacity availability for one DDC.
 *
 * @param {AvailabilityParamenterProgressDetailsExpanded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function availabilityParamenterProgressDetailsExpanded(
	props: AvailabilityParamenterProgressDetailsExpanded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user expands the line of delivey capacity availability for one DDC.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					active_strategies: {
						description: 'The number of active strategies.',
						type: ['number', 'null'],
					},
					ddc_id: {
						description:
							'The Codes of the DDCs the user is taking this action.',
						type: ['array', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'active_strategies',
					'ddc_id',
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Availability Paramenter Progress Details Expanded',
		type: 'object',
	}
	const message = {
		event: 'Availability Paramenter Progress Details Expanded',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Availability Paramenter Progress Details Expanded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user views the Capacity Availability Parameters screen.
 *
 * @param {AvailabilityParametersPageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function availabilityParametersPageViewed(
	props: AvailabilityParametersPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user views the Capacity Availability Parameters screen.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Availability Parameters Page Viewed',
		type: 'object',
	}
	const message = {
		event: 'Availability Parameters Page Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Availability Parameters Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user abort the creating or edition of a capacity availability parameter.
 *
 * @param {CancelSavingAvailabilityParamenter} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cancelSavingAvailabilityParamenter(
	props: CancelSavingAvailabilityParamenter,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user abort the creating or edition of a capacity availability parameter.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Cancel Saving Availability Paramenter',
		type: 'object',
	}
	const message = {
		event: 'Cancel Saving Availability Paramenter',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Cancel Saving Availability Paramenter',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user downloads a file in the  "Client Download" tab in the Imports & Exports Page
 *
 * @param {ClientDownloadFileDownloaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function clientDownloadFileDownloaded(
	props: ClientDownloadFileDownloaded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user downloads a file in the  "Client Download" tab in the Imports & Exports Page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					file_id: {
						description: 'ID of the requested file',
						type: ['string', 'null'],
					},
					response: {
						description: 'The informations retuned to the user',
						type: ['object', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'file_id',
					'response',
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Client Download File Downloaded',
		type: 'object',
	}
	const message = {
		event: 'Client Download File Downloaded',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Client Download File Downloaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user requests a new Client file in the  "Client Download" tab in the Imports & Exports Page
 *
 * @param {ClientDownloadFileRequested} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function clientDownloadFileRequested(
	props: ClientDownloadFileRequested,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user requests a new Client file in the  "Client Download" tab in the Imports & Exports Page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					response: {
						description: 'The informations retuned to the user',
						type: ['object', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'response',
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Client Download File Requested',
		type: 'object',
	}
	const message = {
		event: 'Client Download File Requested',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Client Download File Requested',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when user enters the "Client Download" tab in the Imports & Exports Page
 *
 * @param {ClientDownloadListViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function clientDownloadListViewed(
	props: ClientDownloadListViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when user enters the "Client Download" tab in the Imports & Exports Page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Client Download List Viewed',
		type: 'object',
	}
	const message = {
		event: 'Client Download List Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Client Download List Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user uploads a file in the  "Client Upload" tab in the Imports & Exports Page
 *
 * @param {ClientFileUploaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function clientFileUploaded(
	props: ClientFileUploaded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user uploads a file in the  "Client Upload" tab in the Imports & Exports Page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					file_size: {
						description:
							'The file size that have been uploaded on the platform. Sample: 1MB, 15KBS',
						type: ['number', 'null'],
					},
					response: {
						description: 'The informations retuned to the user',
						type: ['object', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'file_size',
					'response',
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Client File Uploaded',
		type: 'object',
	}
	const message = {
		event: 'Client File Uploaded',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Client File Uploaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user change the current client list page number
 *
 * @param {ClientPaginationChanged} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function clientPaginationChanged(
	props: ClientPaginationChanged,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user change the current client list page number',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					page: {
						description: 'The new page the user has navigated to',
						type: ['number', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'page',
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Client Pagination Changed',
		type: 'object',
	}
	const message = {
		event: 'Client Pagination Changed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Client Pagination Changed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user accesses the client list page
 *
 * @param {ClientScreenPageAccessed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function clientScreenPageAccessed(
	props: ClientScreenPageAccessed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user accesses the client list page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Client Screen Page Accessed',
		type: 'object',
	}
	const message = {
		event: 'Client Screen Page Accessed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Client Screen Page Accessed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user performs a search on the Client list Page
 *
 * @param {ClientSearchPerformed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function clientSearchPerformed(
	props: ClientSearchPerformed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user performs a search on the Client list Page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					query_type: {
						description: 'The type of query the user is tring to do',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					search_text: {
						description: 'The text the user typed in the searchbar',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'query_type',
					'screen_name',
					'search_text',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Client Search Performed',
		type: 'object',
	}
	const message = {
		event: 'Client Search Performed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Client Search Performed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user downloads a file in the  "Client Upload" tab in the Imports & Exports Page
 *
 * @param {ClientUploadFileDownloaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function clientUploadFileDownloaded(
	props: ClientUploadFileDownloaded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user downloads a file in the  "Client Upload" tab in the Imports & Exports Page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					file_id: {
						description: 'ID of the requested file',
						type: ['string', 'null'],
					},
					response: {
						description: 'The informations retuned to the user',
						type: ['object', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'file_id',
					'response',
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Client Upload File Downloaded',
		type: 'object',
	}
	const message = {
		event: 'Client Upload File Downloaded',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Client Upload File Downloaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when user enters the "Client Upload" tab in the Imports & Exports Page
 *
 * @param {ClientUploadListViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function clientUploadListViewed(
	props: ClientUploadListViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when user enters the "Client Upload" tab in the Imports & Exports Page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Client Upload List Viewed',
		type: 'object',
	}
	const message = {
		event: 'Client Upload List Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Client Upload List Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user selects a country in the country selection dropdown
 *
 * @param {CountrySelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function countrySelected(
	props: CountrySelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user selects a country in the country selection dropdown',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Country Selected',
		type: 'object',
	}
	const message = {
		event: 'Country Selected',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Country Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user change the current DDC list page number
 *
 * @param {DdcPaginationChanged} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function ddcPaginationChanged(
	props: DdcPaginationChanged,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user change the current DDC list page number',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					page: {
						description: 'The new page the user has navigated to',
						type: ['number', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'page',
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'DDC Pagination Changed',
		type: 'object',
	}
	const message = {
		event: 'DDC Pagination Changed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'DDC Pagination Changed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user acesses the DDCs list page
 *
 * @param {DdcScreenPageAccessed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function ddcScreenPageAccessed(
	props: DdcScreenPageAccessed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user acesses the DDCs list page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'DDC Screen Page Accessed',
		type: 'object',
	}
	const message = {
		event: 'DDC Screen Page Accessed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'DDC Screen Page Accessed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user performs a search on the DDC list Page
 *
 * @param {DdcSearchPerformed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function ddcSearchPerformed(
	props: DdcSearchPerformed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user performs a search on the DDC list Page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					query_type: {
						description: 'The type of query the user is tring to do',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					search_text: {
						description: 'The text the user typed in the searchbar',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'query_type',
					'screen_name',
					'search_text',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'DDC Search performed',
		type: 'object',
	}
	const message = {
		event: 'DDC Search performed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'DDC Search performed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user delete a capacity availability parameter.
 *
 * @param {DeleteAvailabilityParameter} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function deleteAvailabilityParameter(
	props: DeleteAvailabilityParameter,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user delete a capacity availability parameter.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					payload: {
						description: 'The informations sent by the user',
						type: ['object', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'payload',
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Delete Availability Parameter',
		type: 'object',
	}
	const message = {
		event: 'Delete Availability Parameter',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Delete Availability Parameter',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Understand the User Behavior through the tracking of the pages at the Inventory Management.
 *
 * @param {PageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pageViewed(
	props: PageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Understand the User Behavior through the tracking of the pages at the Inventory Management.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					app_display_name: {
						description:
							'The current name of the app where the event was triggered.',
						type: ['string', 'null'],
					},
					app_id: {
						description:
							'The unique id of the app where the event was triggered.',
						type: ['string', 'null'],
					},
					is_core_event: {
						description:
							'A boolean that identifies rather an occurrence of this event configures as a core action.',
						type: ['boolean', 'null'],
					},
					manufacturer_country: {
						description:
							'(IF APPLICABLE) The country of the manufacturer that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					manufacturer_display_name: {
						description:
							'(IF APPLICABLE) The name of the manufacturer that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					manufacturer_id: {
						description:
							'(IF APPLICABLE) The id of the manufacturer that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					manufacturer_service_model: {
						description:
							'(IF APPLICABLE) The Service model of the manufacturer that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					manufacturer_tier_name: {
						description:
							'(IF APPLICABLE)  The tier of the manufacturer that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					page_label: {
						description: 'The Page title on the front end with localization.',
						type: ['string', 'null'],
					},
					page_name: {
						description: 'The name of the page.',
						type: ['string', 'null'],
					},
					page_url: {
						description: 'The URL of the page where the event was triggered.',
						type: ['string', 'null'],
					},
					referrer: {
						description:
							'URL the user came from to get to the current page. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/login',
						type: ['string', 'null'],
					},
					user_country: {
						description: "Logged user's country",
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					user_permission_group: {
						description:
							'The user permission group that relates to the application and the feature where the action is being executed.',
						type: ['string', 'null'],
					},
					vendor_country: {
						description:
							'(IF APPLICABLE) The country of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_display_name: {
						description:
							'(IF APPLICABLE) The name of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_service_model: {
						description:
							'(IF APPLICABLE) The Service model of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					vendor_tier_name: {
						description:
							'(IF APPLICABLE)  The tier of the vendor that is executing the action or having it executed for them.',
						type: ['string', 'null'],
					},
					view_level: {
						description:
							'(IF APPLICABLE) The view level the action is being executed',
						type: ['string', 'null'],
					},
				},
				required: [
					'app_display_name',
					'app_id',
					'is_core_event',
					'manufacturer_country',
					'manufacturer_display_name',
					'manufacturer_id',
					'manufacturer_service_model',
					'manufacturer_tier_name',
					'page_label',
					'page_name',
					'page_url',
					'user_permission_group',
					'user_email',
					'user_country',
					'vendor_country',
					'vendor_display_name',
					'vendor_id',
					'vendor_service_model',
					'vendor_tier_name',
					'view_level',
					'referrer',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Page Viewed',
		type: 'object',
	}
	const message = {
		event: 'Page Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks to see details os a capacity availability parameter.
 *
 * @param {ParametersDetailsViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function parametersDetailsViewed(
	props: ParametersDetailsViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks to see details os a capacity availability parameter.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					ddc_id: {
						description:
							'The Codes of the DDCs the user is taking this action.',
						type: ['array', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'ddc_id',
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Parameters Details Viewed',
		type: 'object',
	}
	const message = {
		event: 'Parameters Details Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Parameters Details Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user filters the parameters list.
 *
 * @param {ParametersListFiltered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function parametersListFiltered(
	props: ParametersListFiltered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user filters the parameters list.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					filter_data: {
						description: 'The query parameter used for filtering on the screen',
						type: ['object', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'filter_data',
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Parameters List Filtered',
		type: 'object',
	}
	const message = {
		event: 'Parameters List Filtered',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Parameters List Filtered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when user clicks the "Regenerate Delivery Windows" button inside the Edit Client Page
 *
 * @param {RegeneratedClientDeliveryWindows} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function regeneratedClientDeliveryWindows(
	props: RegeneratedClientDeliveryWindows,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when user clicks the "Regenerate Delivery Windows" button inside the Edit Client Page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					customer_account_id: {
						description: 'The customerAccountId of the selected clients',
						type: ['array', 'null'],
					},
					ddc_id: {
						description:
							'The Codes of the DDCs the user is taking this action.',
						type: ['array', 'null'],
					},
					response: {
						description: 'The informations retuned to the user',
						type: ['object', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'customer_account_id',
					'ddc_id',
					'response',
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Regenerated Client Delivery Windows',
		type: 'object',
	}
	const message = {
		event: 'Regenerated Client Delivery Windows',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Regenerated Client Delivery Windows',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when user clicks the "Regenerate Delivery Windows" button inside the Edit DDC Page
 *
 * @param {RegeneratedDdcDeliveryWindows} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function regeneratedDdcDeliveryWindows(
	props: RegeneratedDdcDeliveryWindows,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when user clicks the "Regenerate Delivery Windows" button inside the Edit DDC Page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					ddc_id: {
						description:
							'The Codes of the DDCs the user is taking this action.',
						type: ['array', 'null'],
					},
					response: {
						description: 'The informations retuned to the user',
						type: ['object', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'ddc_id',
					'response',
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Regenerated DDC Delivery Windows',
		type: 'object',
	}
	const message = {
		event: 'Regenerated DDC Delivery Windows',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Regenerated DDC Delivery Windows',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user downloads a file in the  "Rules Download" tab in the Imports & Exports Page
 *
 * @param {RuleDownloadFileDownloaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function ruleDownloadFileDownloaded(
	props: RuleDownloadFileDownloaded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user downloads a file in the  "Rules Download" tab in the Imports & Exports Page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					response: {
						description: 'The informations retuned to the user',
						type: ['object', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'response',
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Rule Download File Downloaded',
		type: 'object',
	}
	const message = {
		event: 'Rule Download File Downloaded',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Rule Download File Downloaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user requests a new Rules file in the  "Rules Download" tab in the Imports & Exports Page
 *
 * @param {RuleDownloadFileRequested} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function ruleDownloadFileRequested(
	props: RuleDownloadFileRequested,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user requests a new Rules file in the  "Rules Download" tab in the Imports & Exports Page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					response: {
						description: 'The informations retuned to the user',
						type: ['object', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'response',
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Rule Download File Requested',
		type: 'object',
	}
	const message = {
		event: 'Rule Download File Requested',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Rule Download File Requested',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when user enters the "Rules Download" tab in the Imports & Exports Page
 *
 * @param {RuleDownloadListViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function ruleDownloadListViewed(
	props: RuleDownloadListViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when user enters the "Rules Download" tab in the Imports & Exports Page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Rule Download List Viewed',
		type: 'object',
	}
	const message = {
		event: 'Rule Download List Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Rule Download List Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user uploads a file in the  "Rules Upload" tab in the Imports & Exports Page
 *
 * @param {RuleFileUploaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function ruleFileUploaded(
	props: RuleFileUploaded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user uploads a file in the  "Rules Upload" tab in the Imports & Exports Page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					file_size: {
						description:
							'The file size that have been uploaded on the platform. Sample: 1MB, 15KBS',
						type: ['number', 'null'],
					},
					response: {
						description: 'The informations retuned to the user',
						type: ['object', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'file_size',
					'response',
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Rule File Uploaded',
		type: 'object',
	}
	const message = {
		event: 'Rule File Uploaded',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Rule File Uploaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user downloads a file in the  "Rules Upload" tab in the Imports & Exports Page
 *
 * @param {RuleUploadFileDownloaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function ruleUploadFileDownloaded(
	props: RuleUploadFileDownloaded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user downloads a file in the  "Rules Upload" tab in the Imports & Exports Page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					file_id: {
						description: 'ID of the requested file',
						type: ['string', 'null'],
					},
					response: {
						description: 'The informations retuned to the user',
						type: ['object', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'file_id',
					'response',
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Rule Upload File Downloaded',
		type: 'object',
	}
	const message = {
		event: 'Rule Upload File Downloaded',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Rule Upload File Downloaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when user enters the "Rules Upload" tab in the Imports & Exports Page
 *
 * @param {RuleUploadListViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function ruleUploadListViewed(
	props: RuleUploadListViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when user enters the "Rules Upload" tab in the Imports & Exports Page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Rule Upload List Viewed',
		type: 'object',
	}
	const message = {
		event: 'Rule Upload List Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Rule Upload List Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when user saves the Client data inside the Edit Client Page
 *
 * @param {SavedClient} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function savedClient(
	props: SavedClient,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when user saves the Client data inside the Edit Client Page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					batch_quantity: {
						description: 'The number of records changed in batch by this event',
						type: ['number', 'null'],
					},
					click_and_collect_changes: {
						description:
							'Identify if there were any changes in the click and collect parameters.',
						type: ['boolean', 'null'],
					},
					customer_account_id: {
						description: 'The customerAccountId of the selected clients',
						type: ['array', 'null'],
					},
					ddc_id: {
						description:
							'The Codes of the DDCs the user is taking this action.',
						type: ['array', 'null'],
					},
					flex_dates_changes: {
						description:
							'Identify if there were any changes in the flex delivery dates',
						type: ['boolean', 'null'],
					},
					has_click_and_collect: {
						description:
							'Identify if the client or DDC has click and collect enabled',
						type: ['boolean', 'null'],
					},
					has_flex_dates: {
						description:
							'Identify if the client or exception rule has flexible delivery days.',
						type: ['boolean', 'null'],
					},
					payload: {
						description: 'The informations sent by the user',
						type: ['object', 'null'],
					},
					response: {
						description: 'The informations retuned to the user',
						type: ['object', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'customer_account_id',
					'ddc_id',
					'payload',
					'response',
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
					'batch_quantity',
					'click_and_collect_changes',
					'has_click_and_collect',
					'flex_dates_changes',
					'has_flex_dates',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Saved Client',
		type: 'object',
	}
	const message = {
		event: 'Saved Client',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Saved Client',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when  when user Saves the Client Rules data inside the Manage Client Rules Page
 *
 * @param {SavedClientRules} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function savedClientRules(
	props: SavedClientRules,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when  when user Saves the Client Rules data inside the Manage Client Rules Page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					batch_quantity: {
						description: 'The number of records changed in batch by this event',
						type: ['number', 'null'],
					},
					customer_account_id: {
						description: 'The customerAccountId of the selected clients',
						type: ['array', 'null'],
					},
					ddc_id: {
						description:
							'The Codes of the DDCs the user is taking this action.',
						type: ['array', 'null'],
					},
					has_flex_dates: {
						description:
							'Identify if the client or exception rule has flexible delivery days.',
						type: ['boolean', 'null'],
					},
					payload: {
						description: 'The informations sent by the user',
						type: ['object', 'null'],
					},
					response: {
						description: 'The informations retuned to the user',
						type: ['object', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'customer_account_id',
					'ddc_id',
					'payload',
					'response',
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
					'batch_quantity',
					'has_flex_dates',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Saved Client Rules',
		type: 'object',
	}
	const message = {
		event: 'Saved Client Rules',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Saved Client Rules',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when user saves the DDC data inside the Edit DDC Page
 *
 * @param {SavedDdc} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function savedDdc(
	props: SavedDdc,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when user saves the DDC data inside the Edit DDC Page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					batch_quantity: {
						description: 'The number of records changed in batch by this event',
						type: ['number', 'null'],
					},
					click_and_collect_changes: {
						description:
							'Identify if there were any changes in the click and collect parameters.',
						type: ['boolean', 'null'],
					},
					ddc_id: {
						description:
							'The Codes of the DDCs the user is taking this action.',
						type: ['array', 'null'],
					},
					has_click_and_collect: {
						description:
							'Identify if the client or DDC has click and collect enabled',
						type: ['boolean', 'null'],
					},
					payload: {
						description: 'The informations sent by the user',
						type: ['object', 'null'],
					},
					response: {
						description: 'The informations retuned to the user',
						type: ['object', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'ddc_id',
					'payload',
					'response',
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
					'batch_quantity',
					'click_and_collect_changes',
					'has_click_and_collect',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Saved DDC',
		type: 'object',
	}
	const message = {
		event: 'Saved DDC',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track('Saved DDC', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * Triggered when  when user Saves the DDC Holidays data inside the Manage DDC Holidays Page
 *
 * @param {SavedDdcHolidays} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function savedDdcHolidays(
	props: SavedDdcHolidays,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when  when user Saves the DDC Holidays data inside the Manage DDC Holidays Page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					batch_quantity: {
						description: 'The number of records changed in batch by this event',
						type: ['number', 'null'],
					},
					ddc_id: {
						description:
							'The Codes of the DDCs the user is taking this action.',
						type: ['array', 'null'],
					},
					payload: {
						description: 'The informations sent by the user',
						type: ['object', 'null'],
					},
					response: {
						description: 'The informations retuned to the user',
						type: ['object', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'ddc_id',
					'payload',
					'response',
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
					'batch_quantity',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Saved DDC Holidays',
		type: 'object',
	}
	const message = {
		event: 'Saved DDC Holidays',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Saved DDC Holidays',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when  when user Saves the DDC Rules data inside the Manage DDC Rules Page
 *
 * @param {SavedDdcRules} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function savedDdcRules(
	props: SavedDdcRules,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when  when user Saves the DDC Rules data inside the Manage DDC Rules Page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					batch_quantity: {
						description: 'The number of records changed in batch by this event',
						type: ['number', 'null'],
					},
					ddc_id: {
						description:
							'The Codes of the DDCs the user is taking this action.',
						type: ['array', 'null'],
					},
					has_flex_dates: {
						description:
							'Identify if the client or exception rule has flexible delivery days.',
						type: ['boolean', 'null'],
					},
					payload: {
						description: 'The informations sent by the user',
						type: ['object', 'null'],
					},
					response: {
						description: 'The informations retuned to the user',
						type: ['object', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'ddc_id',
					'payload',
					'response',
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
					'batch_quantity',
					'has_flex_dates',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Saved DDC Rules',
		type: 'object',
	}
	const message = {
		event: 'Saved DDC Rules',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Saved DDC Rules',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on create a capacity availability parameter.
 *
 * @param {SavingAvailabilityParamenter} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function savingAvailabilityParamenter(
	props: SavingAvailabilityParamenter,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on create a capacity availability parameter.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					payload: {
						description: 'The informations sent by the user',
						type: ['object', 'null'],
					},
					response: {
						description: 'The informations retuned to the user',
						type: ['object', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'payload',
					'response',
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Saving Availability Paramenter',
		type: 'object',
	}
	const message = {
		event: 'Saving Availability Paramenter',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Saving Availability Paramenter',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user starts creating a capacity availability parameter.
 *
 * @param {StartCreatingAvailabilityParamenter} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function startCreatingAvailabilityParamenter(
	props: StartCreatingAvailabilityParamenter,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user starts creating a capacity availability parameter.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Start Creating Availability Paramenter',
		type: 'object',
	}
	const message = {
		event: 'Start Creating Availability Paramenter',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Start Creating Availability Paramenter',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user starts editing a capacity availability parameter.
 *
 * @param {StartEditAvailabilityParamenter} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function startEditAvailabilityParamenter(
	props: StartEditAvailabilityParamenter,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user starts editing a capacity availability parameter.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					ddc_id: {
						description:
							'The Codes of the DDCs the user is taking this action.',
						type: ['array', 'null'],
					},
					response: {
						description: 'The informations retuned to the user',
						type: ['object', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'ddc_id',
					'response',
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Start Edit Availability Paramenter',
		type: 'object',
	}
	const message = {
		event: 'Start Edit Availability Paramenter',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Start Edit Availability Paramenter',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when user enters the Edit Client Page
 *
 * @param {StartedEditClient} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function startedEditClient(
	props: StartedEditClient,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when user enters the Edit Client Page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					batch_quantity: {
						description: 'The number of records changed in batch by this event',
						type: ['number', 'null'],
					},
					customer_account_id: {
						description: 'The customerAccountId of the selected clients',
						type: ['array', 'null'],
					},
					ddc_id: {
						description:
							'The Codes of the DDCs the user is taking this action.',
						type: ['array', 'null'],
					},
					has_click_and_collect: {
						description:
							'Identify if the client or DDC has click and collect enabled',
						type: ['boolean', 'null'],
					},
					has_flex_dates: {
						description:
							'Identify if the client or exception rule has flexible delivery days.',
						type: ['boolean', 'null'],
					},
					multiple: {
						description:
							'Boolean showing if the user entered the screen with multiple clients or just one',
						type: ['boolean', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'customer_account_id',
					'ddc_id',
					'multiple',
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
					'batch_quantity',
					'has_flex_dates',
					'has_click_and_collect',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Started Edit Client',
		type: 'object',
	}
	const message = {
		event: 'Started Edit Client',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Started Edit Client',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when user enters the Edit DDC Page
 *
 * @param {StartedEditDdc} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function startedEditDdc(
	props: StartedEditDdc,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when user enters the Edit DDC Page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					batch_quantity: {
						description: 'The number of records changed in batch by this event',
						type: ['number', 'null'],
					},
					ddc_id: {
						description:
							'The Codes of the DDCs the user is taking this action.',
						type: ['array', 'null'],
					},
					has_click_and_collect: {
						description:
							'Identify if the client or DDC has click and collect enabled',
						type: ['boolean', 'null'],
					},
					multiple: {
						description:
							'Boolean showing if the user entered the screen with multiple clients or just one',
						type: ['boolean', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'ddc_id',
					'multiple',
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
					'batch_quantity',
					'has_click_and_collect',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Started Edit DDC',
		type: 'object',
	}
	const message = {
		event: 'Started Edit DDC',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Started Edit DDC',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when user enters the Manage Client Rules page
 *
 * @param {StartedManageClientRules} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function startedManageClientRules(
	props: StartedManageClientRules,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when user enters the Manage Client Rules page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					batch_quantity: {
						description: 'The number of records changed in batch by this event',
						type: ['number', 'null'],
					},
					customer_account_id: {
						description: 'The customerAccountId of the selected clients',
						type: ['array', 'null'],
					},
					ddc_id: {
						description:
							'The Codes of the DDCs the user is taking this action.',
						type: ['array', 'null'],
					},
					filter_data: {
						description: 'The query parameter used for filtering on the screen',
						type: ['object', 'null'],
					},
					has_flex_dates: {
						description:
							'Identify if the client or exception rule has flexible delivery days.',
						type: ['boolean', 'null'],
					},
					multiple: {
						description:
							'Boolean showing if the user entered the screen with multiple clients or just one',
						type: ['boolean', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'customer_account_id',
					'ddc_id',
					'multiple',
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
					'batch_quantity',
					'has_flex_dates',
					'filter_data',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Started Manage Client Rules',
		type: 'object',
	}
	const message = {
		event: 'Started Manage Client Rules',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Started Manage Client Rules',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when user enters the Manage DDC Holidays page
 *
 * @param {StartedManageDdcHolidays} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function startedManageDdcHolidays(
	props: StartedManageDdcHolidays,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when user enters the Manage DDC Holidays page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					batch_quantity: {
						description: 'The number of records changed in batch by this event',
						type: ['number', 'null'],
					},
					ddc_id: {
						description:
							'The Codes of the DDCs the user is taking this action.',
						type: ['array', 'null'],
					},
					multiple: {
						description:
							'Boolean showing if the user entered the screen with multiple clients or just one',
						type: ['boolean', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'ddc_id',
					'multiple',
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
					'batch_quantity',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Started Manage DDC Holidays',
		type: 'object',
	}
	const message = {
		event: 'Started Manage DDC Holidays',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Started Manage DDC Holidays',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when user enters the Manage DDC Rules page
 *
 * @param {StartedManageDdcRules} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function startedManageDdcRules(
	props: StartedManageDdcRules,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when user enters the Manage DDC Rules page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					batch_quantity: {
						description: 'The number of records changed in batch by this event',
						type: ['number', 'null'],
					},
					ddc_id: {
						description:
							'The Codes of the DDCs the user is taking this action.',
						type: ['array', 'null'],
					},
					filter_data: {
						description: 'The query parameter used for filtering on the screen',
						type: ['object', 'null'],
					},
					has_flex_dates: {
						description:
							'Identify if the client or exception rule has flexible delivery days.',
						type: ['boolean', 'null'],
					},
					multiple: {
						description:
							'Boolean showing if the user entered the screen with multiple clients or just one',
						type: ['boolean', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'ddc_id',
					'multiple',
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
					'batch_quantity',
					'has_flex_dates',
					'filter_data',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Started Manage DDC Rules',
		type: 'object',
	}
	const message = {
		event: 'Started Manage DDC Rules',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Started Manage DDC Rules',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user filters the strategy progress.
 *
 * @param {StrategyProgressFiltered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function strategyProgressFiltered(
	props: StrategyProgressFiltered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user filters the strategy progress.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					filter_data: {
						description: 'The query parameter used for filtering on the screen',
						type: ['object', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'filter_data',
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Strategy Progress Filtered',
		type: 'object',
	}
	const message = {
		event: 'Strategy Progress Filtered',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Strategy Progress Filtered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when Modal to view Client Delivery Windows Opened
 *
 * @param {ViewDeliveryWindows} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function viewDeliveryWindows(
	props: ViewDeliveryWindows,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when Modal to view Client Delivery Windows Opened',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					customer_account_id: {
						description: 'The customerAccountId of the selected clients',
						type: ['array', 'null'],
					},
					ddc_id: {
						description:
							'The Codes of the DDCs the user is taking this action.',
						type: ['array', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action.',
						type: ['string', 'null'],
					},
					selected_country: {
						description:
							'The country that is currently selected when this event occured',
						type: ['string', 'null'],
					},
					user_email: {
						description: 'Logged user email',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: 'The Vendor ID of the item',
						type: ['string', 'null'],
					},
					vendor_name: {
						description:
							'The vendor name that is currently selected when this event occored',
						type: ['string', 'null'],
					},
				},
				required: [
					'customer_account_id',
					'ddc_id',
					'screen_name',
					'selected_country',
					'user_email',
					'vendor_id',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'View Delivery Windows',
		type: 'object',
	}
	const message = {
		event: 'View Delivery Windows',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'View Delivery Windows',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}

const clientAPI = {
	/**
	 * Updates the run-time configuration of this Typewriter client.
	 *
	 * @param {TypewriterOptions} options - the options to upsert
	 *
	 * @typedef {Object} TypewriterOptions
	 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
	 * 		calls are forwarded on to. Defaults to window.analytics.
	 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
	 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
	 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
	 * 		will be logged to stderr.
	 */
	setTypewriterOptions,
	/**
	 * Triggered when the user clicks on load more for na specific day.
	 *
	 * @param {AvailabilityParamenterProgressDetailsCardViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	availabilityParamenterProgressDetailsCardViewed,
	/**
	 * Triggered when the user expands the line of delivey capacity availability for one DDC.
	 *
	 * @param {AvailabilityParamenterProgressDetailsExpanded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	availabilityParamenterProgressDetailsExpanded,
	/**
	 * Triggered when the user views the Capacity Availability Parameters screen.
	 *
	 * @param {AvailabilityParametersPageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	availabilityParametersPageViewed,
	/**
	 * Triggered when the user abort the creating or edition of a capacity availability parameter.
	 *
	 * @param {CancelSavingAvailabilityParamenter} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	cancelSavingAvailabilityParamenter,
	/**
	 * Triggered when the user downloads a file in the  "Client Download" tab in the Imports & Exports Page
	 *
	 * @param {ClientDownloadFileDownloaded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	clientDownloadFileDownloaded,
	/**
	 * Triggered when the user requests a new Client file in the  "Client Download" tab in the Imports & Exports Page
	 *
	 * @param {ClientDownloadFileRequested} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	clientDownloadFileRequested,
	/**
	 * Triggered when user enters the "Client Download" tab in the Imports & Exports Page
	 *
	 * @param {ClientDownloadListViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	clientDownloadListViewed,
	/**
	 * Triggered when the user uploads a file in the  "Client Upload" tab in the Imports & Exports Page
	 *
	 * @param {ClientFileUploaded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	clientFileUploaded,
	/**
	 * Triggered when the user change the current client list page number
	 *
	 * @param {ClientPaginationChanged} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	clientPaginationChanged,
	/**
	 * Triggered when the user accesses the client list page
	 *
	 * @param {ClientScreenPageAccessed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	clientScreenPageAccessed,
	/**
	 * Triggered when the user performs a search on the Client list Page
	 *
	 * @param {ClientSearchPerformed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	clientSearchPerformed,
	/**
	 * Triggered when the user downloads a file in the  "Client Upload" tab in the Imports & Exports Page
	 *
	 * @param {ClientUploadFileDownloaded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	clientUploadFileDownloaded,
	/**
	 * Triggered when user enters the "Client Upload" tab in the Imports & Exports Page
	 *
	 * @param {ClientUploadListViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	clientUploadListViewed,
	/**
	 * Triggered when the user selects a country in the country selection dropdown
	 *
	 * @param {CountrySelected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	countrySelected,
	/**
	 * Triggered when the user change the current DDC list page number
	 *
	 * @param {DdcPaginationChanged} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	ddcPaginationChanged,
	/**
	 * Triggered when the user acesses the DDCs list page
	 *
	 * @param {DdcScreenPageAccessed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	ddcScreenPageAccessed,
	/**
	 * Triggered when the user performs a search on the DDC list Page
	 *
	 * @param {DdcSearchPerformed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	ddcSearchPerformed,
	/**
	 * Triggered when the user delete a capacity availability parameter.
	 *
	 * @param {DeleteAvailabilityParameter} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	deleteAvailabilityParameter,
	/**
	 * Understand the User Behavior through the tracking of the pages at the Inventory Management.
	 *
	 * @param {PageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pageViewed,
	/**
	 * Triggered when the user clicks to see details os a capacity availability parameter.
	 *
	 * @param {ParametersDetailsViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	parametersDetailsViewed,
	/**
	 * Triggered when the user filters the parameters list.
	 *
	 * @param {ParametersListFiltered} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	parametersListFiltered,
	/**
	 * Triggered when user clicks the "Regenerate Delivery Windows" button inside the Edit Client Page
	 *
	 * @param {RegeneratedClientDeliveryWindows} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	regeneratedClientDeliveryWindows,
	/**
	 * Triggered when user clicks the "Regenerate Delivery Windows" button inside the Edit DDC Page
	 *
	 * @param {RegeneratedDdcDeliveryWindows} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	regeneratedDdcDeliveryWindows,
	/**
	 * Triggered when the user downloads a file in the  "Rules Download" tab in the Imports & Exports Page
	 *
	 * @param {RuleDownloadFileDownloaded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	ruleDownloadFileDownloaded,
	/**
	 * Triggered when the user requests a new Rules file in the  "Rules Download" tab in the Imports & Exports Page
	 *
	 * @param {RuleDownloadFileRequested} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	ruleDownloadFileRequested,
	/**
	 * Triggered when user enters the "Rules Download" tab in the Imports & Exports Page
	 *
	 * @param {RuleDownloadListViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	ruleDownloadListViewed,
	/**
	 * Triggered when the user uploads a file in the  "Rules Upload" tab in the Imports & Exports Page
	 *
	 * @param {RuleFileUploaded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	ruleFileUploaded,
	/**
	 * Triggered when the user downloads a file in the  "Rules Upload" tab in the Imports & Exports Page
	 *
	 * @param {RuleUploadFileDownloaded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	ruleUploadFileDownloaded,
	/**
	 * Triggered when user enters the "Rules Upload" tab in the Imports & Exports Page
	 *
	 * @param {RuleUploadListViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	ruleUploadListViewed,
	/**
	 * Triggered when user saves the Client data inside the Edit Client Page
	 *
	 * @param {SavedClient} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	savedClient,
	/**
	 * Triggered when  when user Saves the Client Rules data inside the Manage Client Rules Page
	 *
	 * @param {SavedClientRules} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	savedClientRules,
	/**
	 * Triggered when user saves the DDC data inside the Edit DDC Page
	 *
	 * @param {SavedDdc} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	savedDdc,
	/**
	 * Triggered when  when user Saves the DDC Holidays data inside the Manage DDC Holidays Page
	 *
	 * @param {SavedDdcHolidays} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	savedDdcHolidays,
	/**
	 * Triggered when  when user Saves the DDC Rules data inside the Manage DDC Rules Page
	 *
	 * @param {SavedDdcRules} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	savedDdcRules,
	/**
	 * Triggered when the user clicks on create a capacity availability parameter.
	 *
	 * @param {SavingAvailabilityParamenter} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	savingAvailabilityParamenter,
	/**
	 * Triggered when the user starts creating a capacity availability parameter.
	 *
	 * @param {StartCreatingAvailabilityParamenter} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	startCreatingAvailabilityParamenter,
	/**
	 * Triggered when the user starts editing a capacity availability parameter.
	 *
	 * @param {StartEditAvailabilityParamenter} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	startEditAvailabilityParamenter,
	/**
	 * Triggered when user enters the Edit Client Page
	 *
	 * @param {StartedEditClient} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	startedEditClient,
	/**
	 * Triggered when user enters the Edit DDC Page
	 *
	 * @param {StartedEditDdc} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	startedEditDdc,
	/**
	 * Triggered when user enters the Manage Client Rules page
	 *
	 * @param {StartedManageClientRules} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	startedManageClientRules,
	/**
	 * Triggered when user enters the Manage DDC Holidays page
	 *
	 * @param {StartedManageDdcHolidays} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	startedManageDdcHolidays,
	/**
	 * Triggered when user enters the Manage DDC Rules page
	 *
	 * @param {StartedManageDdcRules} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	startedManageDdcRules,
	/**
	 * Triggered when the user filters the strategy progress.
	 *
	 * @param {StrategyProgressFiltered} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	strategyProgressFiltered,
	/**
	 * Triggered when Modal to view Client Delivery Windows Opened
	 *
	 * @param {ViewDeliveryWindows} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	viewDeliveryWindows,
}

export default new Proxy<typeof clientAPI>(clientAPI, {
	get(target, method) {
		if (typeof method === 'string' && target.hasOwnProperty(method)) {
			return target[method as keyof typeof clientAPI]
		}

		return () => {
			console.warn(`⚠️  You made an analytics call (${String(
				method
			)}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/abi-delta/protocols/tracking-plans/rs_2KjbP6fvwh3121RWt69KQezVy7j`)
			const a = analytics()
			if (a) {
				a.track(
					'Unknown Analytics Call Fired',
					{
						method,
					},
					withTypewriterContext()
				)
			}
		}
	},
})
