import { PageRouteData, PagesIDs } from 'constants/SegmentTracking.constants';
import segment from 'tracking';
import { getReferrer, setReferrer } from 'utils/referrerUtils/referrerUtils';
import { getTrackingDefaultData } from './pageViewUtils';

export const trackPageView = (pageID: PagesIDs): void => {
  const pageData = PageRouteData[pageID];
  const defaultData = getTrackingDefaultData();

  const referrer = getReferrer();

  segment.pageViewed({
    ...defaultData,
    ...pageData,
    referrer,
    page_url: document.location.href,
  });

  setReferrer(document.location.href);
};
