export const APP_NAME = 'Delivery';
export const APP_ID = 'DELIVERY_WINDOW';

export const HierarchyGroups = [
  'LOATDeliveryWindow.BEES Admin',
  'LOATDeliveryWindow.Delivery window Full admin',
  'LOATDeliveryWindow.Date manager',
  'LOATDeliveryWindow.DC manager',
  'LOATDeliveryWindow.Client manager',
  'LOATDeliveryWindow.Flexible window manager',
  'LOATDeliveryWindow.Wholesaler manager',
  'LOATDeliveryWindow.Holiday manager',
  'LOATDeliveryWindow.Delivery capacity availability manager',
  'LOATDeliveryWindow.C&C manager',
  'LOATDeliveryWindow.C&C parameters manager',
  'LOATDeliveryWindow.C&C consumption manager',
  'LOATDeliveryWindow.CSV download manager',
  'LOATDeliveryWindow.Delivery window viewer',
  'LOATDeliveryWindow.C&C viewer',
  'LOATDeliveryWindow.C&C parameters viewer',
  'LOATDeliveryWindow.C&C consumption viewer',
  'LOATDeliveryWindow.Delivery capacity availability viewer',
];

export enum PagesIDs {
  DDCS = 'DDCS',
  DDCS_EDIT = 'DDCS_EDIT',
  DDC_CUSTOM_SETTINGS = 'DDC_CUSTOM_SETTINGS',
  HOLIDAY = 'HOLIDAY',
  DDCS_RULES = 'DDCS_RULES',
  DELIVERY_WINDOW = 'DELIVERY_WINDOW',
  CLIENT_EDIT = 'CLIENT_EDIT',
  CLIENT_RULES = 'CLIENT_RULES',
  UPLOADS_AND_DOWNLOADS = 'UPLOADS_AND_DOWNLOADS',
  UPLOAD_CLIENT_BASE = 'UPLOAD_CLIENT_BASE',
  UPLOAD_RULES_BASE = 'UPLOAD_RULES_BASE',
  CAPACITY_PARAMETERS = 'CAPACITY_PARAMETERS',
  EDIT_CAPACITY_PARAMETERS = 'EDIT_CAPACITY_PARAMETERS',
}

export const PageRouteData = {
  [PagesIDs.DDCS]: {
    page_label: 'DDCs Management',
    page_name: PagesIDs.DDCS,
  },
  [PagesIDs.DDCS_EDIT]: {
    page_label: 'DDCs Management Editing',
    page_name: PagesIDs.DDCS_EDIT,
  },
  [PagesIDs.DDC_CUSTOM_SETTINGS]: {
    page_label: 'DDC Custom Settings Editing',
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
  },
  [PagesIDs.HOLIDAY]: {
    page_label: 'Manage Holidays',
    page_name: PagesIDs.HOLIDAY,
  },
  [PagesIDs.DDCS_RULES]: {
    page_label: 'DDCs Exception Rules',
    page_name: PagesIDs.DDCS_RULES,
  },
  [PagesIDs.DELIVERY_WINDOW]: {
    page_label: 'Delivery Windows',
    page_name: PagesIDs.DELIVERY_WINDOW,
  },
  [PagesIDs.CLIENT_EDIT]: {
    page_label: 'Edit POC-level settings',
    page_name: PagesIDs.CLIENT_EDIT,
  },
  [PagesIDs.CLIENT_RULES]: {
    page_label: 'Clients Exception Rules',
    page_name: PagesIDs.CLIENT_RULES,
  },
  [PagesIDs.UPLOADS_AND_DOWNLOADS]: {
    page_label: 'Upload and Download Files',
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
  },
  [PagesIDs.UPLOAD_CLIENT_BASE]: {
    page_label: 'Upload Client Files',
    page_name: PagesIDs.UPLOAD_CLIENT_BASE,
  },
  [PagesIDs.UPLOAD_RULES_BASE]: {
    page_label: 'Upload Rules Files',
    page_name: PagesIDs.UPLOAD_RULES_BASE,
  },
  [PagesIDs.CAPACITY_PARAMETERS]: {
    page_label: 'Delivery Capacity Availability Parameters',
    page_name: PagesIDs.CAPACITY_PARAMETERS,
  },
  [PagesIDs.EDIT_CAPACITY_PARAMETERS]: {
    page_label: 'Delivery Capacity Availability Parameter Creation',
    page_name: PagesIDs.EDIT_CAPACITY_PARAMETERS,
  },
};
